import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Scrollbar from "react-scrollbars-custom";

import config from "config/common";
import lib from "lib/commons";
import Spinner from "components/Spinner";
import dayjs from "dayjs";

Modal.setAppElement("#root");

const ModalHistory = ({
  matches,
  userHistory,
  voteHistory,
  isGettingHistory,
  getHistory,
  modalStatus,
  closeModal,
  lng
}) => {

  const [currentTab, setCurrentTab] = useState("spin");

  useEffect(() => {
    if (!isGettingHistory) {
      getHistory();
    }
  }, []);

  const afterOpenModal = () => {};

  const selfClose = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={modalStatus}
      onAfterOpen={afterOpenModal}
      onRequestClose={selfClose}
      contentLabel="Example Modal"
      portalClassName="ReactModalPortal"
      overlayClassName=""
      className="animated fadeInDown faster modal-history modal-received-gifts"
    >
      <h2>{lng?.text_history || 'Lịch sử'}</h2>
      <div className="modal-description">
      <div className="row no-gutters">
          <a
            href="#"
            className={`btn-his col-6${
              currentTab == "spin" ? " btn-his--active" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              if (currentTab == "spin") {
                return false;
              }
              setCurrentTab("spin");
            }}
          >
            {lng?.text_foosball_table_gift || 'Quà Bi Lắc'}
          </a>
          <a
            href="#"
            className={`btn-his col-6${
              currentTab == "accumulation" ? " btn-his--active" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              if (currentTab == "accumulation") {
                return false;
              }
              setCurrentTab("accumulation");
            }}
          >
            {lng?.text_accumulation_gifts || 'Quà tích luỹ'}
          </a>
        </div>
        {currentTab == "spin" && (
          <>
        {isGettingHistory ? (
          <Spinner />
        ) : (
          <>
            {/* <table className="table-history">
              <thead>
                <tr>
                  <th width="10%">STT</th>
                  <th width="30%">Tên VP</th>
                  <th width="15%">Trạng thái</th>
                  <th width="25%">Thời gian</th>
                  <th width="20%">Sự kiện</th>
                </tr>
              </thead>
            </table>

            <Scrollbar style={{ height: "21vw" }} noScrollX={true}>
              {userHistory.spin.length > 0 ? (
                <table className="table-history">
                  <tbody>
                    {userHistory.spin.map((his, index) => (
                      <tr key={index}>
                        <td width="10%">
                          <strong>{index + 1}</strong>
                        </td>
                        <td width="30%">{his.reward_name}</td>
                        <td width="15%">{his.status}</td>
                        <td width="25%">{his.create_time}</td>
                        <td width="20%">
                          {his.usage == "spin"
                            ? "Quà bi lắc"
                            : "Quà tích lũy"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Chưa có lịch sử</p>
              )}
            </Scrollbar> */}
            <div className="received-gifts">
              <Scrollbar style={{ height: '30vw' }} noScrollX={true} >
                <div className="received-gifts__list">
                  {userHistory?.spin && userHistory?.spin.length > 0 && userHistory?.spin.map((gift, index) => {
                    let color = ''
                    if(gift?.color == 1) {
                      color = ' yellow'
                    }
                    if(gift?.color == 2 || gift?.color == 3) {
                      color = ' red'
                    }

                    return (
                      <div className="received-gifts__items" key={index}>
                        <div className="received-gifts__item">
                          <div className="received-gifts__left">
                            <div className="received-gifts__medal">
                              {gift?.items.length > 0 && gift?.items.map((item, index) => {
                                return (
                                  <div>
                                    <img src={`/images/medal${item}.png`} alt="" />
                                  </div>
                                )
                              })}
                            </div>
                            <div className={`received-gifts__time ${color}`}>
                              {gift?.create_time} - {gift?.status}
                              {/* 10:00 10.01.2023 - Chưa gửi */}
                              {/* {gift?.reward_name} */}
                            </div>
                          </div>
                          <div className="received-gifts__right">
                            <div className="received-gifts__reward">
                              <img src={gift?.image} alt="" data-tip={gift?.reward_name} />
                            </div>
                            <div className="point">
                              <img src="/images/star.png" alt="" />
                              <span>+{gift?.accumulation_point}</span>
                            </div>
                            <div className={`received-gifts__time ${color} reward-name`}>
                              {/* {dayjs(gift?.current_datetime).format("HH:mm - DD.MM.YYYY")} */}
                              {gift?.reward_name}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Scrollbar>
            </div>
          </>
        )}
        </>
        )}

      {currentTab == "accumulation" && (
          <>
            <table className="table-history">
              <thead>
                <tr>
                  <th width="10%">{lng?.text_numerical_order || 'STT'}</th>
                  <th width="30%">{lng?.text_name_gift || 'Tên VP'}</th>
                  <th width="15%">{lng?.text_status || 'Trạng thái'}</th>
                  <th width="25%">{lng?.text_datetime || 'Thời gian'}</th>
                  <th width="20%">{lng?.text_event || 'Sự kiện'}</th>
                </tr>
              </thead>
            </table>

            <Scrollbar style={{ height: "21vw" }} noScrollX={true}>
              {userHistory?.accumulation && userHistory?.accumulation.length > 0 ? (
                <table className="table-history">
                  <tbody>
                    { userHistory?.accumulation.map((his, index) => (
                      <tr key={index}>
                        <td width="10%">
                          <strong>{index + 1}</strong>
                        </td>
                        <td width="30%">{his.reward_name}</td>
                        <td width="15%">{his.status}</td>
                        <td width="25%">{his.create_time}</td>
                        <td width="20%">
                          {his.usage == "spin"
                            ? lng?.text_foosball_table_gift || 'Quà Bi Lắc'
                            : lng?.text_accumulation_gifts || 'Quà tích luỹ'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>{lng?.text_not_history || 'Chưa có lịch sử'}</p>
              )}
            </Scrollbar>
          </>
        )}
      </div>

    </Modal>
  );
};
export default ModalHistory;
