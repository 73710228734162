import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import config from 'config/common';
import lib from 'lib/commons';

import ModalHistory from 'components/ModalHistory';
import ModalGuide from 'components/ModalGuide';

const Header = ({
	user: {
		user: {
			nickname = '',
			id: userId = null,
			uid = null,
			total_spin_times: totalSpinNum = 0,
			vote_history: voteHistory = [],
		} = {},
		lng = {},
		matches = [],
		userHistory = [],
		eventStarted,
		isGettingHistory,
	} = {},

	getHistory,
}) => {
	let history = useHistory();
	const [modalStatus, setModalStatus] = useState(false);
	const closeModal = () => setModalStatus(false);

	const [modalGuideStatus, setModalGuideStatus] = useState(false);
	const closeGuideModal = () => setModalGuideStatus(false);

	const parsed = queryString.parse(location.search);

	return (
		<>
			<div id="header">
				<div className="top-bar">
					<div className="top-bar__logo">
						<a href={lng?.logo_url || 'https://fo4.garena.vn'} className="logo">
							<img src="/images/logo.png" alt="" />
						</a>
					</div>
					<div className="top-bar__menu">
						<a
							href={
								lng?.homepage_fo4_url || 'https://fo4.garena.vn/bi-lac-t2-2023/'
							}
							className="menu-link"
							target="_blank"
						>
							{lng?.text_homepage || 'Trang chủ'}
						</a>
						<a
							href="#"
							className="menu-link"
							onClick={(e) => {
								e.preventDefault();
								setModalGuideStatus(true);
							}}
						>
							{lng?.text_guide || 'Hướng dẫn'}
						</a>
						<a
							href="#"
							className="menu-link"
							onClick={(e) => {
								e.preventDefault();
								setModalStatus(true);
							}}
						>
							{lng?.text_history || 'Lịch sử'}
						</a>
						<a
							href={lng?.topup_fo4_url || 'https://napthe.vn/app/32837/buy/0'}
							className="menu-link"
							target="_blank"
						>
							{lng?.text_topup || 'Nạp thẻ'}
						</a>
					</div>
					<div className="top-bar__user">
						{!userId ? (
							<a
								href="/user/login"
								className="btn-header btn-header--login"
								onClick={(e) => {
									if (!eventStarted) {
										e.preventDefault();
										lib.showMessage(
											config.demoMsg,
											null,
											lng?.text_notice || 'Thông báo',
											lng?.text_confirm || 'Xác nhận'
										);
										return false;
									}
								}}
							>
								{lng?.text_login || 'Đăng nhập'}
							</a>
						) : (
							<>
								<span className="user-info">
									{lng?.text_coach || 'HLV'}{' '}
									<strong className="nickname">{nickname}</strong>
								</span>
								{parsed.ingame != 1 && (
									<a
										href="/user/logout"
										className="btn-header btn-header--logout"
									>
										{lng?.text_logout || 'Đăng xuất'}
									</a>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			{modalStatus && (
				<ModalHistory
					userHistory={userHistory}
					isGettingHistory={isGettingHistory}
					getHistory={getHistory}
					voteHistory={voteHistory}
					matches={matches}
					modalStatus={modalStatus}
					closeModal={closeModal}
					lng={lng}
				/>
			)}
			{modalGuideStatus && (
				<ModalGuide
					modalStatus={modalGuideStatus}
					closeModal={closeGuideModal}
					lng={lng}
				/>
			)}
		</>
	);
};

export default Header;
