import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Scrollbar from "react-scrollbars-custom";

Modal.setAppElement("#root");

const ModalGuide = ({
  modalStatus,
  closeModal,
  lng
}) => {
  const afterOpenModal = () => {};

  const selfClose = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={modalStatus}
      onAfterOpen={afterOpenModal}
      onRequestClose={selfClose}
      contentLabel="Example Modal"
      portalClassName="ReactModalPortal"
      overlayClassName=""
      className="animated fadeInDown faster modal-guide modal-big modal-history"
    >
      <h2 className="text-neon">{lng?.text_guide || 'Hướng dẫn'}</h2>
      <div className="modal-description">
        <Scrollbar style={{ height: "22vw" }} noScrollX={true}>
          <div className="modal-guide--content text-left" dangerouslySetInnerHTML={{__html: lng?.content_guide || 'Nội dung thể lệ'}}></div>
        </Scrollbar>
      </div>
      <a
        onClick={(event) => selfClose(event)}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        ×
      </a>
    </Modal>
  )
}

export default ModalGuide