export default {
	lng: 'vi', //vi|en
	region: 'VN', //vi|pk
	version: '1',
	domain: 'tacataca.fo4.garena.in.th',
	demo: 0,
	demoMsg: 'Sự kiện sẽ bắt đầu vào ngày 03.02.2023',
	baseUrl: `https://tacataca.fo4.garena.in.th`,
	shareHashtag: 'TacaTaca',
	shareQuote: 'TacaTaca',
	predictGuide: 'https://',
	spinGuide:
		'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/tichdiem_eacc.png',
	prizeList:
		'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/qua_ranking.png',
	prizeListGlobal:
		'https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2022/T4/eacc/eacc_qua.png',
	guideImg:
		'https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2022/T4/eacc/eacc_hd.png',
};
